import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import sidebarReducer from './sidebarSlice';
import ordersReducer from './ordersSlice';
import userReducer from './userSlice';
import artReducer from './artSlice';
import { 
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const rootReducer = combineReducers({
  sidebar: sidebarReducer,
  orders: ordersReducer,
  art: artReducer,
  user: userReducer
});

const persistConfig = {
  key: 'user',
  storage,
  whitelist: ['user', 'orders']
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware => {
    return getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    });
  }
});
