import { createSlice } from '@reduxjs/toolkit';

export const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState: {
    visible: true
  },
  reducers: {
    show: state => {
      state.visible = true
    },
    hide: state => {
      state.visible = false
    },
    set: (state, visible) => {
      state.visible = visible
    }
  }
});

// Action creators are generated for each case reducer function
export const { show, hide } = sidebarSlice.actions;

export default sidebarSlice.reducer;
