import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { BACKEND_URL } from '../App';

export const fetchOrders = createAsyncThunk('orders/fetchOrders', async payload => {
  let response;

  if (payload.store) {
    response = await fetch(`${BACKEND_URL}/orders?model=game_orders&customer=${payload.customer}&store=${payload.store}`);
  } else {
    response = await fetch(`${BACKEND_URL}/orders?model=game_orders&customer=${payload.customer}`);
  }

  const data = await response.json();

  return data;
});

export const selectOrders = state => {
  const orders = state?.orders?.orders;

  return typeof orders === 'object' && Object.keys(orders).length > 0 ? Object.values(orders) : [];
};

export const selectOrder = orderCtrlNo => state => state?.orders?.orders ? state.orders.orders[orderCtrlNo] : null;
export const selectOrdersStatus = state => state.orders.status;

const initialState = {
  orders: [],
  status: 'uninitialized'
};

const ordersSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(fetchOrders.pending, (state, action) => {
      state.status = 'loading';
    }).addCase(fetchOrders.fulfilled, (state, action) => {
      state.status = 'succeeded';
      state.orders = action.payload;
    }).addCase(fetchOrders.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    });
  }
});

export default ordersSlice.reducer;
