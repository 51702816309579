import React, { Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './scss/style.scss';

export let BACKEND_URL;
if (process.env.REACT_APP_MVDEV_MODE === 'local') {
  BACKEND_URL = 'http://localhost:3001';
} else {
  BACKEND_URL = 'https://portalsapi.' + process.env.REACT_APP_ROOT_URL
}

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
const Home = React.lazy(() => import('./views/pages/home/Home'));
const OrderShow = React.lazy(() => import('./components/OrderShow'));
const Configure = React.lazy(() => import('./views/pages/configure/Configure'))

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

const App = () => {
  return (
    <BrowserRouter>
      <Suspense fallback={loading}>
        <Routes>
          <Route exact path="/" name="Login Page" element={<Login />} />
          <Route path="/dashboard/*" name="Main Page" element={<Home />} />
          <Route path="/:orderCtrlNo" name="Order Show Page" element={<OrderShow />} />
          {
            process.env.REACT_APP_MVDEV_MODE != 'prod' && <Route path="/configure" name="Configure Page" element={<Configure />} />
          }
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
