import { createSlice } from '@reduxjs/toolkit';

export const selectUsername = state => state.user.username;
export const selectCustomer = state => state.user.customer;
export const selectStore = state => state.user.store || '';
export const selectCustomerName = state => state.orders?.orders ? Object.values(state.orders.orders)[0]?.cust_name : state.user.customer;

export const userSlice = createSlice({
  name: 'user',
  initialState: { username: '', customer: '', store: '' },
  reducers: {
    setUser: (state, inputs) => {
      state.username = inputs.payload.username;
      state.customer = inputs.payload.customer;
      state.store = inputs.payload.store;
    },
    clearUser: state => {
      state.username = '';
      state.customer = '';
      state.store = '';
    }
  }
});

// Action creators are generated for each case reducer function
export const { setUser, clearUser } = userSlice.actions;

export default userSlice.reducer;
