import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { BACKEND_URL } from '../App';

export const makeIndex = createAsyncThunk('art/makeIndex', async () => {
  try {
    const response = await fetch(BACKEND_URL + '/art/make_index');
    
    if (response.ok) {
      console.log("makeIndex succeeded");
    } else {
      console.log("makeIndex failed");
    }
  } catch (error) {
    console.error("An error occurred while making the index:", error);
    throw error;
  }
});

export const fetchArtForStyle = createAsyncThunk('art/fetchArtForStyle', async style => {  
  const response = await fetch(BACKEND_URL + '/art/for_style/' + style);
  const data = await response.blob();
  const objectUrl = URL.createObjectURL(data);

  return ({
    style: style,
    content: objectUrl
  });
});

export const selectArtForStyle = style => {
  return (state => {
    if (style in state.art.for_style) {
      return state.art.for_style[style];
    } else {
      return '';
    }
  });
};

const initialState = {
  for_style: {}
};

const artSlice = createSlice({
  name: 'art',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(fetchArtForStyle.pending, (state, action) => {
      console.log("PENDING");
    }).addCase(fetchArtForStyle.fulfilled, (state, action) => {
      console.log("FULFILLED");
      state.for_style[action.payload.style] = action.payload.content;
    }).addCase(fetchArtForStyle.rejected, (state, action) => {
      console.log("REJECTED");
    });
  }
});

export default artSlice.reducer;
